import * as React from 'react';

import { Auth } from '../tools/auth';

import { BlobViewer } from './BlobViewer';
import { ScansViewer } from './ScansViewer';
import { TopBar } from './TopBar';
import { UrnViewer } from './UrnViewer';

interface Props {
  auth:   Auth;
  type?:  string;
  id?:    string;
}

export const App: React.FunctionComponent<Props> = (props) => {
  const loggedIn = props.auth && props.auth.isAuthenticated();
  return (
    <div className="app-root">
      <TopBar auth={ props.auth }/>
      { loggedIn && props.id ?
        <>
          { props.type  === 'urns' ?
            <UrnViewer
              type={ props.type }
              urnid={ props.id }
            /> : null
          }
          { props.type  === 'blobs' ?
            <BlobViewer
              type={ props.type }
              urnid={ props.id }
            /> : null
          }
          { props.type  === 'scans' ?
            <ScansViewer
              type="rig_id"
              id={ props.id }
            /> : null
          }
          { props.type  === 'email' ?
            <ScansViewer
              type="email"
              id={ window.atob(props.id) }
            /> : null
          }
        </> : null
        }
    </div>
  );
};
