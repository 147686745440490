import { OnSelectProps } from 'react-json-view';

import { loadConfig } from '../tools/config';
import history from '../tools/history';

import { checkStatus, parseText } from './fetch_helpers';

const URNID_RE = /(\d\d\d\d\d\d\d\d_\d\d\d\d\d\d)__([a-z0-9_]+)__([a-z]+_[a-z]+)__([0-9a-f]{64})/;
const BLOB_RE = /([0-9a-f]{64})/;
const EXTERNAL_ID_RE = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
const RIG_ID_RE = /\bvandra-[0-9]+\b/;

const config = loadConfig();

export const onSelectInJson = (item: OnSelectProps) => {
  if (typeof item.value === 'string') {
    if (item.value.match(URNID_RE)) {
      history.push(`/urns/${item.value}`);
    } else if (item.value.match(BLOB_RE)) {
      history.push(`/blobs/${item.value}`);
    } else if (item.value.match(EXTERNAL_ID_RE)) {
      const accessToken = localStorage.getItem('access_token');
      fetch(`${config.virgaIndex}/links/${item.value}`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(checkStatus)
        .then(parseText)
        .then((response) => {
          if (response === '') {
            return;
          }
          const references = response.trim().split('\n');
          if (references.length > 0) {
            history.push(`/urns/${references[0].split(' ')[0]}`);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (item.value.match(RIG_ID_RE)) {
      history.push(`/scans/${item.value}`);
    }
  }
};
