import * as _ from 'lodash';
import * as PNG from 'fast-png';
import * as React from 'react';
import { default as ReactJson } from 'react-json-view';

import { loadConfig } from '../tools/config';
import { checkStatus } from '../tools/fetch_helpers';
import { onSelectInJson } from '../tools/urn_navigation';

import { colorizeDepthImage } from '../tools/colorizeDepthImage';

const config = loadConfig();

function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

interface Props {
  type:  string;
  urnid: string;
  simple: boolean;
}

interface State {
  blob: {
    type: 'json';
    data: any;
  } | {
    type: 'image';
    data: string | ArrayBuffer | null;
  } | {
    type: 'text';
    data: string;
  } | {
    type: 'loading';
  } | {
    type: 'error';
  };
  rotationIndex: number;
}

export class BlobViewer extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    simple: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      blob: { type: 'loading' },
      rotationIndex: 1,
    };
  }

  componentWillMount() {
    this.doUrlRequest();
  }

  componentDidUpdate(prevProps: Props) {
    // Typical usage (don't forget to compare props):
    if (this.props.urnid !== prevProps.urnid) {
      // Setting the state to loading is avoided because it causes flickering of preview.
      // this.setState({ blob: { type: 'loading' } });
      this.throttledUrlRequest();
    }
  }

  doUrlRequest = async () => {
    const accessToken = localStorage.getItem('access_token');
    const url = `${config.virga}/${this.props.type}/${this.props.urnid}`;
    const response = await fetch(url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    try {
      checkStatus(response);

      if (response.headers.get('content-type') === 'text/plain; charset=utf-8') {
        const data = await response.text();
          // Try parsing as JSON data.
        try {
          const json_data = JSON.parse(data);
          this.setState({ blob: { type: 'json', data: json_data } });
          return;
        } catch (err) {
          if (err.name !== 'SyntaxError') {
            throw err;
          }
        }

        // Show as text.
        this.setState({ blob: { type: 'text', data } });
        return;
      }

      // Treat as an image.
      const blob = await response.blob();

      // Check if it's a depth image
      if (response.headers.get('content-type') === 'image/png') {
        const buffer = await blob.arrayBuffer();
        const decoded = PNG.decode(buffer);
        if (decoded.channels == 1 && decoded.depth == 16) {
          console.log("PNG is 16-bit grayscale, treating it as depth image");
          const colorized = colorizeDepthImage(decoded);
          const encoded = PNG.encode(colorized);
          const reader = new FileReader();
          reader.onloadend = () => this.setState({
            blob: { type: 'image', data: reader.result },
          });
          reader.readAsDataURL(new Blob([encoded]));
          return;
        }
      }

      // Not a depth image, show as is.
      const reader = new FileReader();
      reader.onloadend = () => this.setState({
        blob: { type: 'image', data: reader.result },
      });
      reader.readAsDataURL(blob);
      return;
    } catch (e) {
      console.error(e);
      this.setState({ blob: { type: 'error' } });
      return;
    }
  }

  throttledUrlRequest = _.throttle(this.doUrlRequest, 100);

  rotateImageClockwise = () => this.setState(state => ({
    rotationIndex: state.rotationIndex === 4 ?
      1 : state.rotationIndex + 1,
  }))

  rotateImageCounterClockwise = () => this.setState(state => ({
    rotationIndex: state.rotationIndex === 1 ?
      4 : state.rotationIndex - 1,
  }))

  render() {
    switch (this.state.blob.type) {
      case 'loading':
        return <div>Loading...</div>;
      case 'error':
        return <div>Failed to load blob, see console.</div>;
      case 'text':
        return (
          <p className="white-space-wrapping">
            { this.state.blob.data }
          </p>
        );
      case 'json':
        return (
          <ReactJson
            src={ this.state.blob.data }
            name={ null }
            indentWidth={ 2 }
            collapsed={ 2 }
            displayObjectSize={false}
            displayDataTypes={false}
            onSelect={ onSelectInJson }
          />
        );
      case 'image':
        return (
          <div>
            <img
              className={ `rotation-position-${this.state.rotationIndex}` }
              src={ this.state.blob.data as string }
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
            { !this.props.simple ?
              <div>
                <button
                  className="button counterClockwise"
                  onClick={ this.rotateImageCounterClockwise }
                >
                  Rotate Counterclockwise ⭯
                </button>
                <button
                  className="button clockwise"
                  onClick={ this.rotateImageClockwise }
                >
                  Rotate Clockwise ⭮
                </button>
              </div>
              : null
            }
          </div>
        );
      default:
        return assertNever(this.state.blob);
    }
  }
}
