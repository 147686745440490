export type Timespan =
  'day' |
  'week' |
  'month' |
  'year' |
  'unlimited';

export const timespans: [Timespan, string][] = [
  ['day', 'Day'],
  ['week', 'Week'],
  ['month', 'Month (30 days)'],
  ['year', 'Year (365 days)'],
  ['unlimited', 'Unlimited'],
];

export function getTimespanInMilliseconds(timespan: Timespan): number {
  return {
    day: 1 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000,
    unlimited: 30 * 365 * 24 * 60 * 60 * 1000, // 30 years is close enough to unlimited for us.
  }[timespan];
}
