import * as React from 'react';
import { Route, RouteComponentProps, Router, Switch } from 'react-router-dom';

import { Auth } from '../tools/auth';
import history from '../tools/history';

import { App } from './App';
import { Callback } from './Callback';

const auth = new Auth();

type RoutePropsWithoutArgs = RouteComponentProps<{}, {}, {}>;

type AppRouteProps = RouteComponentProps<{
  type?: string,
  id?: string,
}, {}, {}>;

const handleAuthentication = (nextState: RoutePropsWithoutArgs) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

export const MainRoutes: React.FunctionComponent<{}> = () => (
  <Router history={ history }>
    <div>
      <Switch>
        <Route
          exact
          path="/"
          render={ (props: RoutePropsWithoutArgs) => <App auth={auth}/> }
        />
        <Route
          path="/callback"
          render={(props: RoutePropsWithoutArgs) => {
            handleAuthentication(props);
            return <Callback/>;
          }}
        />
        <Route
          path="/:type/:id"
          render={ (props: AppRouteProps) => <App auth={auth} {...props.match.params} /> }
        />
      </Switch>
    </div>
  </Router>
);
