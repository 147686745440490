import * as _ from 'lodash';
import * as React from 'react';

import { loadConfig } from '../tools/config';
import { checkStatus, parseJSON, parseText } from '../tools/fetch_helpers';
import history from '../tools/history';

const config = loadConfig();

interface Props {
  loggedIn: boolean;
}

interface State {
  searchText: string;
  rigIdMatches: string[];
  urnidMatches: string[];
  externalIdMatches: string[];
  emailMatches: string[];
}

export class SearchBar extends React.Component<Props, State> {
  state: State = {
    searchText:        '',
    rigIdMatches:      [],
    urnidMatches:      [],
    externalIdMatches: [],
    emailMatches:      [],
  };

  urnFetch = Promise.resolve();
  linksFetch = Promise.resolve();

  performSearch = () => {
    const accessToken = localStorage.getItem('access_token');
    const trimmedSearchText = this.state.searchText.trim();

    // TODO(Rasmus): Make a query to hewer to get list of scanners.
    if (trimmedSearchText.startsWith('vandra-')) {
      this.setState({
        rigIdMatches:      [trimmedSearchText],
        urnidMatches:      [],
        externalIdMatches: [],
        emailMatches:      [],
      });
      return;
    }

    if (trimmedSearchText.includes('@')) {
      this.setState({
        rigIdMatches:      [],
        urnidMatches:      [],
        externalIdMatches: [],
        emailMatches:      [trimmedSearchText],
      });
      return;
    }

    this.urnFetch = fetch(`${config.virga}/urns/${trimmedSearchText}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((urn) => {
        this.setState({ urnidMatches: [urn.meta.urnid] });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ urnidMatches: [] });
      });

    this.linksFetch = fetch(`${config.virgaIndex}/links/${trimmedSearchText}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(checkStatus)
      .then(parseText)
      .then((response) => {
        if (response === '') {
          this.setState({ externalIdMatches: [] });
          return;
        }
        const references = response.trim().split('\n');
        this.setState({ externalIdMatches: references });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ externalIdMatches: [] });
      });
  }

  debouncedSearch = _.debounce(this.performSearch, 200);

  onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, this.debouncedSearch);
  }

  gotoUrn = (urnid: string) => history.push(`/urns/${urnid}`);

  gotoScansByRigId = (rigId: string) => history.push(`/scans/${rigId}`);

  gotoScansByEmail = (email: string) => history.push(`/email/${window.btoa(email)}`);

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.debouncedSearch.flush();

    Promise.all([this.urnFetch, this.linksFetch])
      .finally(() => this.setState((state) => {
        if (state.rigIdMatches.length > 0) {
          this.gotoScansByRigId(state.rigIdMatches[0]);
          return;
        }
        if (state.emailMatches.length > 0) {
          this.gotoScansByEmail(state.emailMatches[0]);
          return;
        }
        const list = [...state.urnidMatches, ...state.externalIdMatches];
        if (list.length > 0) {
          this.gotoUrn(list[0]);
        }
      }));
  }

  render() {
    return (
      <>
        <div className="search-bar">
          <form onSubmit={ this.onSubmit }>
            <input
              type="text"
              placeholder={ this.props.loggedIn ?
                'Search...' : 'Please log in before searching...'
              }
              onChange={ this.onTextChange }
              value={ this.state.searchText }
              disabled={ !this.props.loggedIn }
            />
          </form>
        </div>
        { (
          this.state.rigIdMatches.length > 0 ||
          this.state.urnidMatches.length > 0 ||
          this.state.externalIdMatches.length > 0
        ) ?
          <div className="search-results">
            {
              this.state.rigIdMatches.map(rigId => (
                <div
                  key={ `rig-id-match-${rigId}` }
                  onClick={ () => this.gotoScansByRigId(rigId) }
                >
                  { rigId }
                </div>
              ))
            }
            {
              this.state.urnidMatches.map(urnid => (
                <div
                  key={ `urnid-match-${urnid}` }
                  onClick={ () => this.gotoUrn(urnid) }
                >
                  { urnid }
                </div>
              ))
            }
            {
              this.state.externalIdMatches.map(match => (
                <div
                  key={ `external-id-match-${match}` }
                  onClick={ () => this.gotoUrn(match) }
                >
                  { match }
                </div>
              ))
            }
          </div>
          : null
        }
        </>
    );
  }
}
