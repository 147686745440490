import * as React from 'react';

import { Timespan, timespans } from '../tools/timespan';

interface Props {
  timespan:  Timespan;
  onChange:  (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TimespanSelector: React.FunctionComponent<Props> = (props: Props) => (
  <>
    { timespans.map(([key, label]) => (
      <div key={ key }>
        <label>
          <input
            type="radio"
            name="timespan"
            value={ key }
            checked={ props.timespan === key }
            onChange={ props.onChange }
          />
          { label }
        </label>
      </div>
    ))}
  </>
);
