import * as _ from 'lodash';
import * as React from 'react';
import { default as ReactJson } from 'react-json-view';

import { loadConfig } from '../tools/config';
import {
  checkStatus,
  encodeUrlWithParams,
  formatHewerTimeStamp,
  hashEmail,
  parseJSON,
} from '../tools/fetch_helpers';
import { getTimespanInMilliseconds, Timespan } from '../tools/timespan';
import { onSelectInJson } from '../tools/urn_navigation';

import { TimespanSelector } from './TimespanSelector';

const config = loadConfig();

type HewerQueryParams = {
  rig_id: string,
  time: string,
} | {
  rig_id: string,
} | {
  email_hash: string,
};

export interface ScanInfo {
  external_link: string | null;
  external_result: {
    blobs: {
      'ground.png':                    string | null,
      'left.obj':                      string | null,
      'measurement_descriptions.json': string | null,
      'measurements.json':             string | null,
      'mesh_lines_left.json':          string | null,
      'mesh_lines_right.json':         string | null,
      'right.obj':                     string | null,
      'scene.json':                    string | null,
    };
    urnids: {
      reconstruction: string | null;
      sizing:         string | null;
    };
  };
  identification: {
    email_hash: string | null;
  };
  latest_result: {
    classification: ScanClassification | null;
    urnids: {
      reconstruction: string;
    };
  };
  original_result: {
    classification: ScanClassification | null;
    error:          ErrorClass | null;
    outcome:        'failure' | 'success';
    urnids: {
      reconstruction: string;
    };
  };
  rig_id: string; // eg. 'vandra-123'.
  time:   string; // eg. '2018-04-18 13:01:26.218402'.
  ui: {
    chart_locale: string | null; // eg. 'eu', 'mp'.
    chart_url:    string | null; // eg. 'ws2eu', 'ws2mondo'.
    language:     string | null; // eg. 'en', 'de'.
  };
  urnids: {
    capture:       string;
    capture_meta:  string;
    external_link: string | null;
  };
  user_input: {
    age_group: 'adults' | 'kids' | null;
    consent:   null; // TODO(Rasmus): What can this be?
    gender:    'female' | 'male' | 'na' | null;
  };
}

export type ScanClassification =
  'normal_barefeet'   |
  'normal_dark_socks' |
  'normal_plastic'    |
  'normal_socks';

export type ErrorClass =
  'bad_input_data'       |
  'covered_dot'          |
  'crash'                |
  'empty_scanner'        |
  'invisible_socks'      |
  'moving_subject'       |
  'poor_calibration'     |
  'strong_light'         |
  'troublesome_pants'    |
  'unstable_ir_projector';

type FilterClasses = ScanClassification | 'not_specified' | 'error' | 'other';

const getTimeParams = (timespan: Timespan): {} | { time: string } => {
  if (timespan === 'unlimited') {
    return {};
  }
  const timespanMilliseconds: number = getTimespanInMilliseconds(timespan);

  const from = formatHewerTimeStamp(new Date(Date.now() - timespanMilliseconds));
  const to = formatHewerTimeStamp(new Date());
  return { time: `[${from}..${to})` };
};

const getFilterClass = (scan: ScanInfo) => {
  if (scan.original_result.error) {
    return 'error';
  }
  if (scan.original_result.classification) {
    if (!scan.original_result.classification.startsWith('normal_')) {
      return 'other';
    }
    return scan.original_result.classification;
  }
  return 'not_specified';
};

interface Props {
  type: 'rig_id' | 'email';
  id: string;
}

interface State {
  scans: ScanInfo[] | 'loading' | 'error';
  timespan: Timespan;
  filterClassification: {
    [key in FilterClasses]: boolean;
  };
}

export class ScansViewer extends React.Component<Props, State> {
  state: State = {
    scans: 'loading',
    timespan: 'day',
    filterClassification: {
      normal_barefeet:   true,
      normal_dark_socks: true,
      normal_plastic:    true,
      normal_socks:      true,
      not_specified:     true,
      error:             true,
      other:             true,
    },
  };

  componentWillMount() {
    this.doUrlRequest();
  }

  doUrlRequest = () => {
    const accessToken = localStorage.getItem('access_token');
    const params: HewerQueryParams = this.props.type === 'rig_id' ? {
      rig_id: this.props.id,
      ...getTimeParams(this.state.timespan),
    } : {
      email_hash: hashEmail(this.props.id),
    };
    fetch(
      encodeUrlWithParams(`${config.hewer}/scans`, params),
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
      .then(checkStatus)
      .then(parseJSON)
      .then(response => this.setState({ scans: response }))
      .catch(console.error);
  }

  onChangeTimespan = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      timespan: event.target.value as Timespan,
      scans: 'loading',
    // tslint:disable-next-line:align
    }, this.doUrlRequest);
  }

  onChangeFilterClassification = (event: React.ChangeEvent<HTMLInputElement>) => {
    const scanClass = event.target.name as FilterClasses;
    this.setState(prevState => ({
      filterClassification: {
        ...prevState.filterClassification,
        [scanClass]: !prevState.filterClassification[scanClass],
      },
    }));
  }

  render() {
    let sortedScans = _.cloneDeep(this.state.scans);
    const scansPerClass: { [key in FilterClasses]: number } = {
      normal_barefeet: 0,
      normal_dark_socks: 0,
      normal_plastic: 0,
      normal_socks: 0,
      not_specified: 0,
      error: 0,
      other: 0,
    };
    if (sortedScans instanceof Array) {
      for (const scan of sortedScans) {
        const classification = getFilterClass(scan);
        scansPerClass[classification] += 1;
      }
      sortedScans = sortedScans.filter((scan) => {
        const classification = getFilterClass(scan);
        return this.state.filterClassification[classification];
      });
      sortedScans.sort((a, b) => a.time.localeCompare(b.time));
    }
    return (
      <>
        { this.props.type === 'rig_id' ? (
          <>
            <h3>'Rig ID'</h3>
            <div>{ this.props.id }</div>
            <h3>Timespan</h3>
            <TimespanSelector
              timespan={ this.state.timespan }
              onChange={ this.onChangeTimespan }
            />
          </>
          ) : (
          <>
            <h3>Email</h3>
            <div>{ this.props.id }</div>
            <div>{ hashEmail(this.props.id) }</div>
          </>
        )}
        <h3>Scans</h3>
        <div>
          { Object.keys(this.state.filterClassification).map((key: ScanClassification) => (
            <div key={ key }>
              <label style={{ fontWeight: scansPerClass[key] ? 'bold' : 'normal' }}>
                <input
                  type="checkbox"
                  checked={ this.state.filterClassification[key] }
                  onChange={ this.onChangeFilterClassification }
                  name={ key }
                />
                { `${key} (${scansPerClass[key]})` }
              </label>
            </div>
          )) }
        </div>
        <div>
          { sortedScans instanceof Array && this.state.scans instanceof Array ?
            <div>
              <h4>Showing { sortedScans.length } of { this.state.scans.length } scans.</h4>
              <ReactJson
                src={ sortedScans }
                name={ null }
                indentWidth={ 2 }
                collapsed={ false }
                displayObjectSize={false}
                displayDataTypes={false}
                onSelect={ onSelectInJson }
              />
            </div>
            :
            <p>{ sortedScans }</p>
          }
        </div>
      </>
    );
  }
}
