import * as React from 'react';
import { default as ReactJson } from 'react-json-view';

import { loadConfig } from '../tools/config';
import { checkStatus, parseJSON, parseText } from '../tools/fetch_helpers';
import history from '../tools/history';
import { Urn } from '../tools/urn';
import { onSelectInJson } from '../tools/urn_navigation';

import { BlobViewer } from './BlobViewer';

const config = loadConfig();

interface UrnReference {
  urnhash: string;
  urnid: string;
  name: string;
}

interface Props {
  type: string;
  urnid: string;
}

interface State {
  references: UrnReference[] | 'loading' | 'error';
  urn?: Urn;
  previewSliderFraction: number;
}

export class UrnViewer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      references: 'loading',
      previewSliderFraction: 0.5,
    };
  }

  componentWillMount() {
    this.doUrlRequest();
  }

  doUrlRequest = () => {
    const accessToken = localStorage.getItem('access_token');

    fetch(`${config.virga}/${this.props.type}/${this.props.urnid}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(response => this.setState({ urn: response }))
      .catch(console.error);

    fetch(`${config.virgaIndex}/${this.props.type}/${this.props.urnid}/references`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(checkStatus)
      .then(parseText)
      .then((response) => {
        if (response === '') {
          this.setState({ references: [] });
          return;
        }
        const references = response.trim().split('\n').map((row) => {
          const cols = row.split(' ');
          return {
            urnhash: cols[0],
            urnid: '',
            name: cols[1],
          };
        });
        this.setState({ references }, this.lookupFullUrnidsOfReferences);
      })
      .catch((e) => {
        console.error(e);
        this.setState({ references: 'error' });
      });
  }

  lookupFullUrnidsOfReferences = () => {
    if (typeof this.state.references === 'string') {
      return;
    }
    const accessToken = localStorage.getItem('access_token');
    for (const outerRef of this.state.references) {
      if (outerRef.urnid === '') {
        fetch(`${config.virgaIndex}/urnids/${outerRef.urnhash}`, {
          method: 'get',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then(checkStatus)
          .then(parseText)
          .then(response => this.setState((state) => {
            if (typeof state.references === 'string') {
              return null;
            }
            return {
              references: state.references.map(innerRef =>
                innerRef.urnhash === outerRef.urnhash ? {
                  ...innerRef,
                  urnid: response,
                } : innerRef),
            };
          }));
      }
    }
  }

  render() {
    let my_v_link: string | null = null;
    if (this.state.urn && this.state.urn.output && this.state.urn.output.external_link) {
      my_v_link = `${config.myV}/${this.state.urn.output.external_link}/`;
    }
    let previewBlob: { name: string, hash: string } | null = null;
    let imageBlobs: string[] = [];
    let imageIndex = 0;
    let intermediate_results_hash: string | null = null;
    if (this.state.urn && this.state.urn.blobs) {
      imageBlobs = Object.keys(this.state.urn.blobs).filter(
        name => (name.match(/[0-9]+\.jpe?g/) || name.match(/[0-9]+\.png?g/)));
      imageBlobs.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
      if (imageBlobs.length > 0) {
        imageIndex = Math.max(0, Math.min(imageBlobs.length - 1, Math.floor(
          imageBlobs.length * this.state.previewSliderFraction)));
        const blobName = imageBlobs[imageIndex];
        previewBlob = {
          name: blobName,
          hash: this.state.urn.blobs[blobName],
        };
      }
    }
    if (
      this.state.urn &&
      this.state.urn.blobs &&
      'intermediate_results.json' in this.state.urn.blobs
    ) {
      intermediate_results_hash = this.state.urn.blobs['intermediate_results.json'];
    }
    const onChangePreviewSlider = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        previewSliderFraction: Number(event.target.value) / imageBlobs.length,
      });
    };
    return (
      <>
        <h3>Full urnid</h3>
        <div>
          {this.state.urn ? this.state.urn.meta.urnid : ''}
        </div>
        <h3>References</h3>
        <div>
          {this.state.references === 'loading' ? <div>Loading...</div> :
            this.state.references === 'error' ? <div>Failed to get references.</div> :
              this.state.references.length === 0 ? <div>No urns are referring to this.</div> :
                this.state.references.map(({ urnhash, urnid, name }) => (
                  <div key={`${urnhash} ${name}`}>
                    <a href={`/urns/${urnid || urnhash}`}>
                      {`${urnid || urnhash} ${name}`}
                    </a>
                  </div>
                ))
          }
        </div>
        {my_v_link ?
          <>
            <h3>Link to my.volumental.com</h3>
            <a href={my_v_link}>{my_v_link}</a>
          </> : null
        }
        {previewBlob ?
          <>
            <h3>Preview of {previewBlob.name}</h3>
            <input
              type="range"
              min={0}
              max={imageBlobs.length - 1}
              value={imageIndex}
              onChange={onChangePreviewSlider}
              className="blobPreviewSlider"
            />
            <a
              href={`/blobs/${previewBlob ? previewBlob.hash : ''}`}
              onClick={() => history.push(`/blobs/${previewBlob ? previewBlob.hash : ''}`)}
            >
              <BlobViewer
                type="blobs"
                urnid={previewBlob.hash}
                simple
              />
            </a>
          </>
          : null
        }
        {intermediate_results_hash ?
          <>
            <BlobViewer
              type="blobs"
              urnid={intermediate_results_hash}
              simple
            />
          </>
          : null
        }
        <h3>Urn</h3>
        <div>
          {this.state.urn ?
            <ReactJson
              src={this.state.urn}
              name={null}
              indentWidth={2}
              collapsed={2}
              displayObjectSize={false}
              displayDataTypes={false}
              onSelect={onSelectInJson}
            /> : null
          }
        </div>
      </>
    );
  }
}
