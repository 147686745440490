import { Buffer } from 'buffer';
import { default as sha256 } from 'fast-sha256';

export type ExtendedError = Error & {
  reason?: string,
  status?: number,
};

export const checkStatus = (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error: ExtendedError = new Error(`status ${response.status} - ${response.statusText}`);
  error.reason = response.statusText;
  error.status = response.status;
  throw error;
};

export const parseJSON = (response: Response) => {
  return response.json();
};

export const parseText = (response: Response) => {
  return response.text();
};

export const encodeUrlWithParams = (url: string, params: { [key: string]: string }): string => {
  const encodedParams = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`);
  return `${url}?${encodedParams.join('&')}`;
};

export const formatHewerTimeStamp = (time: Date) => {
  return time.toISOString().replace('T', ' ').replace(/\.[0-9]*Z/, ''); // eg '2019-03-14 14:10:00'.
};

export const formatVirgaIndexTimeStamp = (time: Date) => {
  return time.toISOString(); // eg '2018-06-05T10:17:57Z'.
};

export const hashEmail = (email: string): string => {
  const encoder = new TextEncoder();
  const shaBytes = sha256(encoder.encode(email));
  return Buffer.from(shaBytes).toString('hex');
};
