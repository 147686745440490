import * as auth0 from 'auth0-js';

import { loadConfig } from './config';
import history from './history';

/*
  This code is based on: https://auth0.com/docs/quickstart/spa/react
  Please don't change it unless there is a change in auth0 recommendation!
*/

export class Auth {
  private auth0: auth0.WebAuth;

  constructor() {
    const config = loadConfig();
    const callback_url = `${config.callback}/callback`;

    this.auth0 = new auth0.WebAuth({
      domain: 'volumental.eu.auth0.com',
      clientID: 'S7ph737HWnJTxjKUnmwDueeh6ngd7SKL', // "virga-frontend-spa"
      redirectUri: callback_url,
      audience: 'https://internal.volumental.com',
      responseType: 'token id_token',
      scope: 'openid',
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  handleAuthentication() {
    /* Frontend doesn't verify the token. Backend will verify on request */
    const options = { _idTokenVerification: false };
    this.auth0.parseHash(options, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        // TODO(Rasmus): Don't use localstorage for user_url since it will be shared for all tabs.
        const userUrl = localStorage.getItem('user_url');
        history.replace(userUrl as string);
      } else if (err) {
        history.replace('/');
      }
    });
  }

  login() {
    this.auth0.authorize();
  }

  setSession(authResult: auth0.Auth0DecodedHash) {
    // Set the time that the access token will expire at
    if (authResult.expiresIn !== undefined) {
      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      localStorage.setItem('expires_at', expiresAt);
    } else {
      localStorage.setItem('expires_at', '');
    }
    localStorage.setItem('access_token', authResult.accessToken || '');
    localStorage.setItem('id_token', authResult.idToken || '');
    // navigate to the home route
    history.replace('/home');
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    history.replace('/');
  }

  isAuthenticated() {
    // Workaround for lack of localStorage during testing.
    if (typeof localStorage === 'undefined') {
      return false;
    }
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || 'null');
    if (typeof expiresAt === 'number') {
      return new Date().getTime() < expiresAt;
    }
    return false;
  }
}
