import * as React from 'react';

import { Auth } from '../tools/auth';

import { SearchBar } from './SearchBar';

interface Props {
  auth: Auth;
}

interface State {
  searchText: string;
}

export class TopBar extends React.Component<Props, State> {
  saveUrlAndLogin = () => {
    localStorage.setItem('user_url', window.location.pathname);
    this.props.auth.login();
  }

  render() {
    const loggedIn = this.props.auth && this.props.auth.isAuthenticated();
    return (
      <div className="top-bar">
        <SearchBar loggedIn={ loggedIn }/>
        { loggedIn ?
          <button
            className="button login-button"
            onClick={ this.props.auth.logout }
          >
            Logout
          </button>
          :
          <button
            className="button login-button"
            onClick={ this.saveUrlAndLogin }
          >
            Login
          </button>
        }
      </div>
    );
  }
}
