const LOCAL_DEV_VIRGA_URL = "https://virga.volumental.com";
const LOCAL_DEV_VIRGA_INDEX_URL = "https://virga-index.volumental.com";
const LOCAL_DEV_HEWER_URL = "https://hewer.volumental.com";
const LOCAL_DEV_MYV_URL = "https://my.volumental.com";
const LOCAL_DEV_CALLBACK_URL = "http://localhost:3000";

export const loadConfig = () => ({
  virga: process.env.REACT_APP_VIRGA_URL || LOCAL_DEV_VIRGA_URL,
  virgaIndex: process.env.REACT_APP_VIRGA_INDEX_URL || LOCAL_DEV_VIRGA_INDEX_URL,
  hewer: process.env.REACT_APP_HEWER_URL || LOCAL_DEV_HEWER_URL,
  myV: process.env.REACT_APP_MYV_URL || LOCAL_DEV_MYV_URL,
  callback: process.env.REACT_APP_CALLBACK_URL || LOCAL_DEV_CALLBACK_URL,
});
